.modal {
    width: 100%;
    max-width: 500px;
}

.disableScroll {
    overflow: hidden;
}

.formContainer {
    display: flex;
    flex-direction: column;
}

.cardNumberRow {
    margin-bottom: 8px;
    position: relative;
    width: 100%;
}

.disableAutocomplete input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }
}

.inputError {
    div,
    &:hover div,
    input:focus + div {
        border-color: #fe4641 !important;
    }
}

.cardFrontBottom {
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.brandImageContainer,
.errorIconContainer {
    position: absolute;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brandImageContainer {
    top: 12px;
}

.errorIconContainer {
    top: 16px;
}

.inputAttachContainer {
    position: absolute;
    top: 16px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.tooltip {
    width: 290px;
    transform: translate(8px, 25px);
}

.brandImage {
    height: 24px;
}

.formItemCardExpires,
.formItemCvv {
    position: relative;
    width: 50%;
}

.formItemCardExpires {
    margin-right: 8px;
}

.formItemFullWidth {
    margin: 0;
    width: auto;
    flex-grow: 1;
}

.cardNumberInput,
.cardExpires,
.cvvInput {
    color: #18181b;
    font-size: 13px;
    outline: none;
    border: none;
    background: transparent;
    padding: 0px;

    &:hover {
        outline: none;
        border: none;
        background: transparent;
        padding: 0px;
    }
}

.confirmTips {
    color: #898993;
    font-size: 11px;
    line-height: 14px;

    a {
        color: #18181b;
    }
}

.confirmTipsText {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.safeReminderText {
    display: flex;
    align-items: center;

    color: #a0a0ab;

    svg {
        margin-right: 4px;
    }
}

.saveCard {
    margin: 12px 0 8px;

    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.errorMessage {
    margin-top: 4px;
    font-size: 11px;
    line-height: 12px;
    color: #fe4641;
}

.invisible {
    margin: 0;
    opacity: 0;
    visibility: hidden;
    width: 0px;
}

.defaultVisibility {
    transition: opacity 0.5s, visibility 0.5s, width 0.5s;
}

.row {
    margin-top: 16px;
}
